import React from 'react';

export function Warranty() {
  return (
    <section>
      <h1>Para usted y los suyos lo mejor</h1>
      <div className="grid">
        <div>
          <h3>Calidad</h3>
          <p>
            Un centro propio ubicado estratégicamente en CABA, dotado de tecnología de última generación y atendidas por
            profesionales altamente entrenados, supervisados por docentes universitarios.
          </p>
        </div>
        <div>
          <h3>Plena satisfacción y garantías</h3>
          <ul>
            <li>Turnos Inmediatos</li>
            <li>Amplitud Horaria</li>
            <li>Trato Cordial y Personalizado</li>
            <li>Confortables Salas de Relax y Lectura</li>
            <li>Aval Tecnológico</li>
            <li>Responsabilidad Cientifica</li>
            <li>Compromiso Empresario</li>
          </ul>
        </div>
        <div>
          <h3>Bajos costos</h3>
          <p>
            Brindamos a nuestros pacientes soluciones odontológicas fundamentadas en la mejor relación entre calidad,
            costos y eficiencia profesional.
          </p>
        </div>
      </div>
    </section>
  );
}
