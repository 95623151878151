import React from 'react';

import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { Reach } from '../components/reach';
import { Warranty } from '../components/warranty';
import { Video } from '../components/video';

export default function() {
  return (
    <Layout>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <Video id="gpHwh6SW9V0" title="Odontobios" />
      <Warranty />
      <Reach />
    </Layout>
  );
}
