import styles from './reach.module.scss';
import React from 'react';

import reachSrc from '../images/reach.png';

export function Reach() {
  return (
    <section className={styles.host}>
      <h1>Odontología al alcance de todos</h1>
      <div className="img img--grayscale">
        <img src={reachSrc} alt="" />
      </div>
    </section>
  );
}
