import React, { useMemo } from 'react';

import styles from './video.module.scss';

export function Video({ id, title }) {
  const src = useMemo(() => getVideoSrc(id), [id]);

  return (
    <div className={styles.container}>
      <iframe
        className={styles.video}
        src={src}
        title={title}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </div>
  );
}

function getVideoSrc(id) {
  return `https://www.youtube-nocookie.com/embed/${id}?rel=0&amp;controls=0&amp;showinfo=0`;
}
